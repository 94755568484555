@import "~antd/dist/antd.less";

.main-content.auth-container {
    margin-top: 50px;
    flex: 1;

    .ant-form-item-extra {
        padding-top: 5px;
    }
}

.login-form-forgot {
    float: right;
}

.ant-col-rtl .login-form-forgot {
    float: left;
}

@body-background: #7e8da4;