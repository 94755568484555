@import "~antd/dist/antd.less";

.header-wrapper {
    max-width: 850px;
    margin: auto;
}
.ant-layout-header.main-nav {
    background: none;
    padding: 0px 25px;
    margin: 25px 0px;

    & > div {
        margin: auto;
        max-width: 850px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

@body-background: #7e8da4;